
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { LOGIN_PAGE_NAME } from '@/router'

@Component({
  name: ''
})
export default class ErrorPage extends Vue {
  get message () {
    return this.$route.params.message
  }

  handleClick () {
    this.$router.push({ name: LOGIN_PAGE_NAME })
  }
}
